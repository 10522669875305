/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { earth as a, moon as f, mars as e } from "./Ellipsoid.js";
const i = Math.PI / 180,
  t = /(?:SPHEROID|ELLIPSOID)\[([^\]]+)]/i,
  r = a.radius,
  n = a.eccentricitySquared,
  s = {
    a1: r * n,
    a2: r * n * r * n,
    a3: r * n * n / 2,
    a4: r * n * r * n * 2.5,
    a5: r * n + r * n * n / 2,
    a6: 1 - n
  },
  d = {
    4267: {
      a: 6378206.4,
      f: 1 / 294.9786982
    },
    4269: {
      a: 6378137,
      f: 1 / 298.257222101
    },
    4326: {
      a: a.radius,
      f: a.flattening
    },
    4490: {
      a: 6378137,
      f: 1 / 298.257222101
    },
    104900: {
      a: 2439700,
      f: 0
    },
    104901: {
      a: 6051e3,
      f: 0
    },
    104902: {
      a: 6051800,
      f: 0
    },
    104903: {
      a: f.radius,
      f: f.flattening
    },
    104904: {
      a: 3393400,
      f: 1 / 192.0430107526882
    },
    104905: {
      a: e.radius,
      f: e.flattening
    },
    104906: {
      a: 6200,
      f: 0
    },
    104907: {
      a: 11100,
      f: 0
    },
    104908: {
      a: 71492e3,
      f: .06487439154031222
    },
    104909: {
      a: 8200,
      f: 0
    },
    104910: {
      a: 83500,
      f: 0
    },
    104911: {
      a: 1e4,
      f: 0
    },
    104912: {
      a: 2409300,
      f: 0
    },
    104913: {
      a: 15e3,
      f: 0
    },
    104914: {
      a: 4e4,
      f: 0
    },
    104915: {
      a: 1562090,
      f: 0
    },
    104916: {
      a: 2632345,
      f: 0
    },
    104917: {
      a: 85e3,
      f: 0
    },
    104918: {
      a: 1821460,
      f: 0
    },
    104919: {
      a: 5e3,
      f: 0
    },
    104920: {
      a: 12e3,
      f: 0
    },
    104921: {
      a: 3e4,
      f: 3
    },
    104922: {
      a: 18e3,
      f: 0
    },
    104923: {
      a: 14e3,
      f: 0
    },
    104924: {
      a: 49300,
      f: 0
    },
    104925: {
      a: 60268e3,
      f: 1 / 10.2079945799458
    },
    104926: {
      a: 16e3,
      f: 0
    },
    104927: {
      a: 9500,
      f: 0
    },
    104928: {
      a: 56e4,
      f: 0
    },
    104929: {
      a: 249400,
      f: 0
    },
    104930: {
      a: 59500,
      f: 0
    },
    104931: {
      a: 16e3,
      f: 0
    },
    104932: {
      a: 133e3,
      f: 0
    },
    104933: {
      a: 718e3,
      f: 0
    },
    104934: {
      a: 888e3,
      f: 0
    },
    104935: {
      a: 1986300,
      f: 0
    },
    104936: {
      a: 1e4,
      f: 0
    },
    104937: {
      a: 41900,
      f: 0
    },
    104938: {
      a: 11e4,
      f: 0
    },
    104939: {
      a: 50100,
      f: 0
    },
    104940: {
      a: 764e3,
      f: 0
    },
    104941: {
      a: 11e3,
      f: 0
    },
    104942: {
      a: 529800,
      f: 0
    },
    104943: {
      a: 2575e3,
      f: 0
    },
    104944: {
      a: 25559e3,
      f: 1 / 43.61604095563141
    },
    104945: {
      a: 578900,
      f: 0
    },
    104946: {
      a: 33e3,
      f: 0
    },
    104947: {
      a: 21e3,
      f: 0
    },
    104948: {
      a: 13e3,
      f: 0
    },
    104949: {
      a: 31e3,
      f: 0
    },
    104950: {
      a: 27e3,
      f: 0
    },
    104951: {
      a: 42e3,
      f: 0
    },
    104952: {
      a: 235800,
      f: 0
    },
    104953: {
      a: 761400,
      f: 0
    },
    104954: {
      a: 15e3,
      f: 0
    },
    104955: {
      a: 54e3,
      f: 0
    },
    104956: {
      a: 77e3,
      f: 0
    },
    104957: {
      a: 27e3,
      f: 0
    },
    104958: {
      a: 788900,
      f: 0
    },
    104959: {
      a: 584700,
      f: 0
    },
    104960: {
      a: 24764e3,
      f: .01708124697141011
    },
    104961: {
      a: 74e3,
      f: 0
    },
    104962: {
      a: 79e3,
      f: 0
    },
    104963: {
      a: 104e3,
      f: .14423076923076922
    },
    104964: {
      a: 29e3,
      f: 0
    },
    104965: {
      a: 17e4,
      f: 0
    },
    104966: {
      a: 208e3,
      f: 0
    },
    104967: {
      a: 4e4,
      f: 0
    },
    104968: {
      a: 1352600,
      f: 0
    },
    104969: {
      a: 1195e3,
      f: 0
    },
    104970: {
      a: 593e3,
      f: 0
    },
    104971: {
      a: e.radius,
      f: 0
    },
    104972: {
      a: 47e4,
      f: 0
    },
    104973: {
      a: 255e3,
      f: 0
    },
    104974: {
      a: 2439400,
      f: 0
    }
  };
export { s as earthEllipsoidConstants, d as spheroids, i as toRadians, t as wktSpheroidRegex };