/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { getProjector as r, projectorCache as t, copy3 as o } from "./projectors.js";
function n(n, e, f, u, c, i, s = 1) {
  const l = r(e, c, t);
  if (null == l) return !1;
  if (l === o) {
    if (n === u && f === i) return !0;
    const r = f + 3 * s;
    for (let t = f, o = i; t < r; t++, o++) u[o] = n[t];
    return !0;
  }
  const p = f + 3 * s;
  for (let r = f, t = i; r < p; r += 3, t += 3) l(n, r, u, t);
  return !0;
}
export { n as projectBuffer };