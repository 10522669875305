/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { rad2deg as n, clamp as C, deg2rad as l, asinClamped as e } from "../../core/mathUtils.js";
import { getMetersPerUnitForSR as E } from "../../core/unitUtils.js";
import { isSphericalECEF as t, WGS84ECEFSpatialReferenceLike as S, SphericalPCPFMarsLike as r, SphericalPCPFMoonLike as u } from "../ellipsoidUtils.js";
import { moon as R, mars as a, earth as A } from "../support/Ellipsoid.js";
import { earthEllipsoidConstants as P } from "../support/geodesicConstants.js";
import { isWGS84 as _, isWebMercator as c, isPlateCarree as s, isMars as o, isMoon as M, equals as i } from "../support/spatialReferenceUtils.js";
import { SupportedGCSWkids as O } from "../support/SupportedGCSWkids.js";
var N;
!function (n) {
  n[n.UNKNOWN = 0] = "UNKNOWN", n[n.SPHERICAL_ECEF = 1] = "SPHERICAL_ECEF", n[n.WGS84 = 2] = "WGS84", n[n.WEB_MERCATOR = 3] = "WEB_MERCATOR", n[n.WGS84_ECEF = 4] = "WGS84_ECEF", n[n.CGCS2000 = 5] = "CGCS2000", n[n.SPHERICAL_MARS_PCPF = 6] = "SPHERICAL_MARS_PCPF", n[n.GCSMARS2000 = 7] = "GCSMARS2000", n[n.SPHERICAL_MOON_PCPF = 8] = "SPHERICAL_MOON_PCPF", n[n.GCSMOON2000 = 9] = "GCSMOON2000", n[n.LON_LAT = 10] = "LON_LAT", n[n.PLATE_CARREE = 11] = "PLATE_CARREE";
}(N || (N = {}));
const L = {
  [N.WGS84]: {
    [N.CGCS2000]: f,
    [N.GCSMARS2000]: null,
    [N.GCSMOON2000]: null,
    [N.LON_LAT]: f,
    [N.SPHERICAL_ECEF]: x,
    [N.SPHERICAL_MARS_PCPF]: null,
    [N.SPHERICAL_MOON_PCPF]: null,
    [N.UNKNOWN]: null,
    [N.WEB_MERCATOR]: h,
    [N.PLATE_CARREE]: d,
    [N.WGS84]: f,
    [N.WGS84_ECEF]: X
  },
  [N.CGCS2000]: {
    [N.CGCS2000]: f,
    [N.GCSMARS2000]: null,
    [N.GCSMOON2000]: null,
    [N.LON_LAT]: f,
    [N.SPHERICAL_ECEF]: x,
    [N.SPHERICAL_MARS_PCPF]: null,
    [N.SPHERICAL_MOON_PCPF]: null,
    [N.UNKNOWN]: null,
    [N.WEB_MERCATOR]: h,
    [N.PLATE_CARREE]: d,
    [N.WGS84]: f,
    [N.WGS84_ECEF]: X
  },
  [N.GCSMARS2000]: {
    [N.CGCS2000]: null,
    [N.GCSMARS2000]: f,
    [N.GCSMOON2000]: null,
    [N.LON_LAT]: f,
    [N.SPHERICAL_ECEF]: null,
    [N.SPHERICAL_MARS_PCPF]: k,
    [N.SPHERICAL_MOON_PCPF]: null,
    [N.UNKNOWN]: null,
    [N.WEB_MERCATOR]: null,
    [N.PLATE_CARREE]: null,
    [N.WGS84]: null,
    [N.WGS84_ECEF]: null
  },
  [N.GCSMOON2000]: {
    [N.CGCS2000]: null,
    [N.GCSMARS2000]: null,
    [N.GCSMOON2000]: f,
    [N.LON_LAT]: f,
    [N.SPHERICAL_ECEF]: null,
    [N.SPHERICAL_MARS_PCPF]: null,
    [N.SPHERICAL_MOON_PCPF]: b,
    [N.UNKNOWN]: null,
    [N.WEB_MERCATOR]: null,
    [N.PLATE_CARREE]: null,
    [N.WGS84]: null,
    [N.WGS84_ECEF]: null
  },
  [N.WEB_MERCATOR]: {
    [N.CGCS2000]: I,
    [N.GCSMARS2000]: null,
    [N.GCSMOON2000]: null,
    [N.LON_LAT]: I,
    [N.SPHERICAL_ECEF]: T,
    [N.SPHERICAL_MARS_PCPF]: null,
    [N.SPHERICAL_MOON_PCPF]: null,
    [N.UNKNOWN]: null,
    [N.WEB_MERCATOR]: f,
    [N.PLATE_CARREE]: K,
    [N.WGS84]: I,
    [N.WGS84_ECEF]: p
  },
  [N.WGS84_ECEF]: {
    [N.CGCS2000]: Y,
    [N.GCSMARS2000]: null,
    [N.GCSMOON2000]: null,
    [N.LON_LAT]: Y,
    [N.SPHERICAL_ECEF]: Z,
    [N.SPHERICAL_MARS_PCPF]: null,
    [N.SPHERICAL_MOON_PCPF]: null,
    [N.UNKNOWN]: null,
    [N.WEB_MERCATOR]: $,
    [N.PLATE_CARREE]: m,
    [N.WGS84]: Y,
    [N.WGS84_ECEF]: f
  },
  [N.SPHERICAL_ECEF]: {
    [N.CGCS2000]: D,
    [N.GCSMARS2000]: null,
    [N.GCSMOON2000]: null,
    [N.LON_LAT]: D,
    [N.SPHERICAL_ECEF]: f,
    [N.SPHERICAL_MARS_PCPF]: null,
    [N.SPHERICAL_MOON_PCPF]: null,
    [N.UNKNOWN]: null,
    [N.WEB_MERCATOR]: J,
    [N.PLATE_CARREE]: w,
    [N.WGS84]: D,
    [N.WGS84_ECEF]: Q
  },
  [N.SPHERICAL_MARS_PCPF]: {
    [N.CGCS2000]: null,
    [N.GCSMARS2000]: z,
    [N.GCSMOON2000]: null,
    [N.LON_LAT]: z,
    [N.SPHERICAL_ECEF]: null,
    [N.SPHERICAL_MARS_PCPF]: f,
    [N.SPHERICAL_MOON_PCPF]: null,
    [N.UNKNOWN]: null,
    [N.WEB_MERCATOR]: null,
    [N.PLATE_CARREE]: null,
    [N.WGS84]: null,
    [N.WGS84_ECEF]: null
  },
  [N.SPHERICAL_MOON_PCPF]: {
    [N.CGCS2000]: null,
    [N.GCSMARS2000]: null,
    [N.GCSMOON2000]: v,
    [N.LON_LAT]: v,
    [N.SPHERICAL_ECEF]: null,
    [N.SPHERICAL_MARS_PCPF]: null,
    [N.SPHERICAL_MOON_PCPF]: f,
    [N.UNKNOWN]: null,
    [N.WEB_MERCATOR]: null,
    [N.PLATE_CARREE]: null,
    [N.WGS84]: null,
    [N.WGS84_ECEF]: null
  },
  [N.UNKNOWN]: {
    [N.CGCS2000]: null,
    [N.GCSMARS2000]: null,
    [N.GCSMOON2000]: null,
    [N.LON_LAT]: null,
    [N.SPHERICAL_ECEF]: null,
    [N.SPHERICAL_MARS_PCPF]: null,
    [N.SPHERICAL_MOON_PCPF]: null,
    [N.UNKNOWN]: f,
    [N.WEB_MERCATOR]: null,
    [N.PLATE_CARREE]: null,
    [N.WGS84]: null,
    [N.WGS84_ECEF]: null
  },
  [N.LON_LAT]: {
    [N.CGCS2000]: f,
    [N.GCSMARS2000]: f,
    [N.GCSMOON2000]: f,
    [N.LON_LAT]: f,
    [N.SPHERICAL_ECEF]: x,
    [N.SPHERICAL_MARS_PCPF]: k,
    [N.SPHERICAL_MOON_PCPF]: b,
    [N.UNKNOWN]: null,
    [N.WEB_MERCATOR]: h,
    [N.PLATE_CARREE]: d,
    [N.WGS84]: f,
    [N.WGS84_ECEF]: X
  },
  [N.PLATE_CARREE]: {
    [N.CGCS2000]: U,
    [N.GCSMARS2000]: null,
    [N.GCSMOON2000]: null,
    [N.LON_LAT]: U,
    [N.SPHERICAL_ECEF]: B,
    [N.SPHERICAL_MARS_PCPF]: null,
    [N.SPHERICAL_MOON_PCPF]: null,
    [N.UNKNOWN]: null,
    [N.WEB_MERCATOR]: j,
    [N.PLATE_CARREE]: f,
    [N.WGS84]: U,
    [N.WGS84_ECEF]: q
  }
};
function G(n, C, l = Cn()) {
  return null == n || null == C ? null : nn(n, C, l).projector;
}
function W(n, C) {
  switch (G(n, C, En)) {
    case f:
      return "copy3";
    case x:
      return "wgs84ToSphericalECEF";
    case h:
      return "wgs84ToWebMercator";
    case d:
      return "wgs84ToPlateCarree";
    case X:
      return "wgs84ToWGS84ECEF";
    case I:
      return "webMercatorToWGS84";
    case T:
      return "webMercatorToSphericalECEF";
    case p:
      return "webMercatorToWGS84ECEF";
    case K:
      return "webMercatorToPlateCarree";
    case Y:
      return "wgs84ECEFToWGS84";
    case Z:
      return "wgs84ECEFToSphericalECEF";
    case $:
      return "wgs84ECEFToWebMercator";
    case D:
      return "sphericalECEFToWGS84";
    case J:
      return "sphericalECEFToWebMercator";
    case z:
      return "sphericalMarsPCPFToMars2000";
    case v:
      return "sphericalMoonPCPFToMoon2000";
    case Q:
      return "sphericalECEFToWGS84ECEF";
    case k:
      return "mars2000ToSphericalPCPF";
    case b:
      return "moon2000ToSphericalPCPF";
    default:
      return null;
  }
}
function F(n, C) {
  return n ? C.spatialReference === n ? C.spatialReferenceId : (C.spatialReference = n, "metersPerUnit" in C && (C.metersPerUnit = E(n, 1)), t(n) ? C.spatialReferenceId = N.SPHERICAL_ECEF : _(n) ? C.spatialReferenceId = N.WGS84 : c(n) ? C.spatialReferenceId = N.WEB_MERCATOR : s(n) ? C.spatialReferenceId = N.PLATE_CARREE : n.wkt === S.wkt ? C.spatialReferenceId = N.WGS84_ECEF : n.wkid === O.CGCS2000 ? C.spatialReferenceId = N.CGCS2000 : n.wkt === r.wkt ? C.spatialReferenceId = N.SPHERICAL_MARS_PCPF : n.wkt === u.wkt ? C.spatialReferenceId = N.SPHERICAL_MOON_PCPF : o(n) ? C.spatialReferenceId = N.GCSMARS2000 : M(n) ? C.spatialReferenceId = N.GCSMOON2000 : C.spatialReferenceId = N.UNKNOWN) : N.UNKNOWN;
}
function f(n, C, l, e) {
  n !== l && (l[e++] = n[C++], l[e++] = n[C++], l[e] = n[C]);
}
function I(n, C, l, e) {
  l[e] = Sn * (n[C] / Rn), l[e + 1] = Sn * (un - 2 * Math.atan(Math.exp(-n[C + 1] / Rn))), l[e + 2] = n[C + 2];
}
function T(n, C, l, e) {
  const E = n[C] / Rn,
    t = un - 2 * Math.atan(Math.exp(-n[C + 1] / Rn)),
    S = Rn + n[C + 2],
    r = Math.cos(t) * S;
  l[e] = Math.cos(E) * r, l[e + 1] = Math.sin(E) * r, l[e + 2] = Math.sin(t) * S;
}
function p(n, C, l, e) {
  I(n, C, l, e), X(l, e, l, e);
}
function H(n, l, e, E, t) {
  const S = .4999999 * rn,
    r = C(tn * n[l + 1], -S, S),
    u = Math.sin(r);
  e[E++] = tn * n[l] * t.radius, e[E++] = t.halfSemiMajorAxis * Math.log((1 + u) / (1 - u)), e[E] = n[l + 2];
}
function h(n, C, l, e) {
  H(n, C, l, e, A);
}
function d(n, C, l, e) {
  l[e] = n[C] * an, l[e + 1] = n[C + 1] * an, l[e + 2] = n[C + 2];
}
function U(n, C, l, e) {
  l[e] = n[C] * An, l[e + 1] = n[C + 1] * An, l[e + 2] = n[C + 2];
}
function K(n, C, l, e) {
  I(n, C, l, e), d(l, e, l, e);
}
function m(n, C, l, e) {
  Y(n, C, l, e), d(l, e, l, e);
}
function w(n, C, l, e) {
  D(n, C, l, e), d(l, e, l, e);
}
function B(n, C, l, e) {
  U(n, C, l, e), x(l, e, l, e);
}
function j(n, C, l, e) {
  U(n, C, l, e), h(l, e, l, e);
}
function q(n, C, l, e) {
  U(n, C, l, e), X(l, e, l, e);
}
function g(n, C, l, e, E) {
  const t = E + n[C + 2],
    S = tn * n[C + 1],
    r = tn * n[C],
    u = Math.cos(S) * t;
  l[e] = Math.cos(r) * u, l[e + 1] = Math.sin(r) * u, l[e + 2] = Math.sin(S) * t;
}
function b(n, C, l, e) {
  g(n, C, l, e, R.radius);
}
function k(n, C, l, e) {
  g(n, C, l, e, a.radius);
}
function x(n, C, l, e) {
  g(n, C, l, e, A.radius);
}
function y(n, C, l, E, t) {
  const S = n[C],
    r = n[C + 1],
    u = n[C + 2],
    R = Math.sqrt(S * S + r * r + u * u),
    a = e(u / (0 === R ? 1 : R)),
    A = Math.atan2(r, S);
  l[E++] = Sn * A, l[E++] = Sn * a, l[E] = R - t;
}
function v(n, C, l, e) {
  y(n, C, l, e, R.radius);
}
function z(n, C, l, e) {
  y(n, C, l, e, a.radius);
}
function D(n, C, l, e) {
  y(n, C, l, e, A.radius);
}
function J(n, C, l, e) {
  D(n, C, l, e), h(l, e, l, e);
}
function Q(n, C, l, e) {
  D(n, C, l, e), X(l, e, l, e);
}
function V(n, C, l, e, E) {
  const t = tn * n[C],
    S = tn * n[C + 1],
    r = n[C + 2],
    u = Math.sin(S),
    R = Math.cos(S),
    a = E.radius / Math.sqrt(1 - E.eccentricitySquared * u * u);
  l[e++] = (a + r) * R * Math.cos(t), l[e++] = (a + r) * R * Math.sin(t), l[e++] = (a * (1 - E.eccentricitySquared) + r) * u;
}
function X(n, C, l, e) {
  V(n, C, l, e, A);
}
function Y(n, C, l, e) {
  const E = P,
    t = n[C],
    S = n[C + 1],
    r = n[C + 2];
  let u, R, a, _, c, s, o, M, i, O, N, L, G, W, F, f, I, T, p, H, h;
  u = Math.abs(r), R = t * t + S * S, a = Math.sqrt(R), _ = R + r * r, c = Math.sqrt(_), H = Math.atan2(S, t), s = r * r / _, o = R / _, W = E.a2 / c, F = E.a3 - E.a4 / c, o > .3 ? (M = u / c * (1 + o * (E.a1 + W + s * F) / c), p = Math.asin(M), O = M * M, i = Math.sqrt(1 - O)) : (i = a / c * (1 - s * (E.a5 - W - o * F) / c), p = Math.acos(i), O = 1 - i * i, M = Math.sqrt(O)), N = 1 - A.eccentricitySquared * O, L = A.radius / Math.sqrt(N), G = E.a6 * L, W = a - L * i, F = u - G * M, I = i * W + M * F, f = i * F - M * W, T = f / (G / N + I), p += T, h = I + f * T / 2, r < 0 && (p = -p), l[e++] = Sn * H, l[e++] = Sn * p, l[e] = h;
}
function Z(n, C, l, e) {
  Y(n, C, l, e), x(l, e, l, e);
}
function $(n, C, l, e) {
  Y(n, C, l, e), h(l, e, l, e);
}
function nn(n, C, l) {
  if (null == n || null == C || l.source.spatialReference === n && l.dest.spatialReference === C) return l;
  const e = F(n, l.source),
    E = F(C, l.dest);
  return e === N.UNKNOWN && E === N.UNKNOWN ? i(n, C) ? l.projector = f : l.projector = null : l.projector = L[e][E], l;
}
function Cn() {
  return {
    source: {
      spatialReference: null,
      spatialReferenceId: N.UNKNOWN,
      metersPerUnit: 1
    },
    dest: {
      spatialReference: null,
      spatialReferenceId: N.UNKNOWN,
      metersPerUnit: 1
    },
    projector: f
  };
}
const ln = {
    spatialReference: null,
    spatialReferenceId: N.UNKNOWN
  },
  en = {
    spatialReference: null,
    spatialReferenceId: N.UNKNOWN
  },
  En = Cn(),
  tn = l(1),
  Sn = n(1),
  rn = Math.PI,
  un = .5 * rn,
  Rn = A.radius,
  an = Rn * rn / 180,
  An = 180 / (Rn * rn);
export { N as ProjectionID, F as classifySpatialReference, f as copy3, Cn as createEmptyProjectorCache, en as destClassificationCache, G as getProjector, W as getProjectorName, An as invPlateCarreeScale, H as lonLatToWebMercatorComparable, nn as populateProjectorCache, L as projectionTable, En as projectorCache, ln as sourceClassificationCache, y as sphericalPCPFtoLonLatElevation, h as wgs84ToWebMercator };